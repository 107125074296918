import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class RestService {
  constructor(private httpClient: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    }),
    withCredentials: false
  };

  public sendGetRequest(path: String) {
    return this.httpClient.get(environment.REST_API_SERVER + path, this.httpOptions);
  }

  public sendExternalGetRequest(uri: string) {
    return this.httpClient.get(uri);
  }

  public sendPostRequest(path: String, payload: String) {
    return this.httpClient.post(environment.REST_API_SERVER + path, payload, this.httpOptions);
  }
  public sendDeleteRequest(path: String) {
    return this.httpClient.delete(environment.REST_API_SERVER + path);
  }
}
