import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestService } from "../services/rest.service";
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-files',
  templateUrl: 'files.page.html',
  styleUrls: ['files.page.scss']
})
export class FilesPage {
  item;

  constructor(private REST: RestService, public modalController: ModalController, private navParams: NavParams) {
    this.item = this.navParams.get('item');
  }

  add() {
    console.log(this.item);
    this.REST.sendPostRequest("file_add.php", JSON.stringify({ item: this.item })).subscribe((data: any[]) => {
      if( data.toString() == "true" ) {
        this.modalController.dismiss();
      }
    });
  }

  fileChanged(event) {
    this.item.file_name = event.target.files[0].name;
    const reader = new FileReader();
    reader.onload = () => {
      this.item.file_content = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
  }
}
