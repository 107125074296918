import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestService } from "../services/rest.service";
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-add',
  templateUrl: 'add.page.html',
  styleUrls: ['add.page.scss']
})
export class AddPage {
  item;

  constructor(private REST: RestService, public modalController: ModalController, private navParams: NavParams) {
    this.item = this.navParams.get('item');
  }

  edit() {
    this.REST.sendPostRequest("edit.php", JSON.stringify({ item: this.item })).subscribe((data: any[]) => {
      if( data.toString() == "true" ) {
        this.modalController.dismiss();
      }
    });
  }

  add() {
    this.REST.sendPostRequest("add.php", JSON.stringify({ item: this.item })).subscribe((data: any[]) => {
      if( data.toString() == "true" ) {
        this.modalController.dismiss();
      }
    });
  }
}
