import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: "docs/:qr_id",
        children: [
          {
            path: "",
            loadChildren: () => import("./docs/docs.module").then((m) => m.DocsPageModule),
          },
        ],
      },
      {
        path: "admin",
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
      },
      {
        path: "",
        loadChildren: () => import("./home/home.module").then((m) => m.HomePageModule),
      }
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
